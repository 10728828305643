//
//
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.
@font-face {
    font-family: 'nexa';
    src: url('../fonts/fontfabric_-_nexa-book-webfont.woff2') format('woff2'),
         url('../fonts/fontfabric_-_nexa-book-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}
@font-face {
    font-family: 'nexa';
    src: url('../fonts/nexa_bold-webfont.woff2') format('woff2'),
         url('../fonts/nexa_bold-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}
@font-face {
    font-family: 'nexa';
    src: url('../fonts/fontfabric_-_nexablack-webfont.woff2') format('woff2'),
         url('../fonts/fontfabric_-_nexablack-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;

}
@font-face {
    font-family: 'nexa';
    src: url('../fonts/fontfabric_-_nexaheavy-webfont.woff2') format('woff2'),
         url('../fonts/fontfabric_-_nexaheavy-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}
.navbar {
  .nav-item.small {
    i {
      margin-top: 4px;
    }
  }
}

.p {
	font-family: $font-family-base;
	font-size: $font-size-base;
	font-weight: $font-weight-base;
	line-height: $line-height-base;
	&.body-color {
		color: $gray-700;
	}
	&.lead {
		font-size: 1.25rem;
    	font-weight: 300;
	}
}

.fill-danger {
  fill: $danger;
}

.fill-white {
  fill: $white;
}

.btn {
  text-transform: uppercase;
  &.lead {
    font-size: $font-size-lg;
  }
  &.base {
    font-size: $font-size-base !important;
  }
  &.font-sm {
    font-size: $font-size-sm !important;
  }
}

.nav-link {
  font-weight: $font-weight-semi-bold;
  @extend .text-white;
}
@include media-breakpoint-up(lg) {
  .home-icons {
    top: -40px;
  }
}
body {
  @extend .bg-white;
}
.bg-signe {
  background: url(../img/signe-fond.svg) no-repeat bottom right;
}
.bg-signe-purple {
  background: url(../img/signe-fond-purple.svg) no-repeat bottom center;
  background-size: 50%;
}
.bg-pink {
  background-color: $pink;
}
.home-chiffres {
  border-bottom: 5px dashed $white;
}
.text-gradient-pink {
  background:-webkit-gradient(left top,left bottom,color-stop(0,transparent),color-stop(60%,transparent),color-stop(60%,$pink-text-gradient),color-stop(100%,$pink-text-gradient));background:linear-gradient(180deg,transparent 0,transparent 60%,$pink-text-gradient 0,$pink-text-gradient);
}
.text-gradient-danger {
  background:-webkit-gradient(left top,left bottom,color-stop(0,transparent),color-stop(60%,transparent),color-stop(60%,$danger),color-stop(100%,$danger));background:linear-gradient(180deg,transparent 0,transparent 60%,$danger 0,$danger);
}
.text-gradient-purple {
  background:-webkit-gradient(left top,left bottom,color-stop(0,transparent),color-stop(60%,transparent),color-stop(60%,$purple-text-gradient),color-stop(100%,$purple-text-gradient));background:linear-gradient(180deg,transparent 0,transparent 60%,$purple-text-gradient 0,$purple-text-gradient);
}
.text-gradient-dark {
  background:-webkit-gradient(left top,left bottom,color-stop(0,transparent),color-stop(60%,transparent),color-stop(60%,$dark-text-gradient),color-stop(100%,$dark-text-gradient));background:linear-gradient(180deg,transparent 0,transparent 60%,$dark-text-gradient 0,$dark-text-gradient);
}
.text-gradient-purple-dark {
  background:-webkit-gradient(left top,left bottom,color-stop(0,transparent),color-stop(60%,transparent),color-stop(60%,$purple),color-stop(100%,$purple));background:linear-gradient(180deg,transparent 0,transparent 60%,$purple 0,$purple);
}
.link {
  span.lead {
    position: relative;
    top: 2px;
  }
}
.full-p {
  padding: 5rem 4.5rem !important;
}
h1,.h1, .display-3{
  line-height: 1.1;
}
.card {
  &.agenda {
    border-radius: 0 0 0 0;
    border-top: 3px solid $purple !important;
    @extend .card.hover-effect:hover;
    img.card-img {
      border-radius: 0 0 0 0;
    }
  }
  &.agenda-list {
    border-radius: 0 0 0 0;
    @extend .card.hover-effect:hover;
    img.card-img {
      border-radius: 0 0 0 0;
    }
  }
}
.bg-gradient-danger {
  background:-webkit-gradient(left top,left bottom,color-stop(0,transparent),color-stop(40%,transparent),color-stop(40%,$pink-text-gradient),color-stop(100%,$pink-text-gradient));background:linear-gradient(180deg,transparent 0,transparent 40%,$pink-text-gradient 0,$pink-text-gradient);
}
.overflow-hidden {
  overflow: hidden;
}
.bg-gray {
  background-color: $gray-200;
}
.bg-purple {
  background-color: $purple;
}
.bg-purple-light {
  background-color: $purple-light;
}
.dropdown-arrow{
  &:after{
    background: dropdown-arrow($white,180);
    opacity: .5;
    position: relative;
    top: 2px;
  }
}
.list-social-links{
  a{
    background: transparent !important;
    &:hover {
      background: lighten($dark, 10%) !important;
    }
  }
}
.arrow {
  fill: $dark;
}

.navbar-brand {
  svg {
    height: 35px;
  }
}
@include media-breakpoint-up(sm) {
  .negative-head {
    position: absolute;
  }
  .navbar-brand {
    svg {
      height: 45px;
    }
  }
}

.valign-center {
  td {
    vertical-align: middle;
  }
}

@include media-breakpoint-up(lg) {
  .wrap-border {
    border-left: 1px solid $gray-200;
    min-height: 50%;
    h3.h4 {
      position: relative;
      &::after {
        content: "";
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 15px solid $gray-200;
        position: absolute;
        left: -36px;
        top: 10px;
      }
    }
  }
}
