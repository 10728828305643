// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

$transition-time: .2s;
$transition-time-fast: .1s;
$transition-time-slow: .4s;

$breadcrumb-divider-font-family: 'Material Icons';
$slider-arrow-icon-font-family: 'Material Icons';
